@import 'tailwindcss/base';
@import 'tailwindcss/utilities';

select {
  outline: none;
}

.backdrop {
  position: fixed;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(113, 118, 124, 0.45);
  backdrop-filter: blur(5px);
  z-index: 998;
}
